<template>
    <div class="live-content">
        <el-scrollbar class="live-wrap">
            <div class="top-tab">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                    <el-breadcrumb-item>网络推广</el-breadcrumb-item>
                    <el-breadcrumb-item>直播间管理</el-breadcrumb-item>
                    <el-breadcrumb-item>直播间控制台</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="btn-box">
                    <el-button type="primary" @click="goBack">返回</el-button>
                </div>
            </div>
            <div class="main-content">
                <div class="content-item">
                    <span class="content-title">直播控制台</span>
                    <div class="item-content">
                        <div class="top-item-content">
                            <div class="top-left">
                                <img :src="liveInfo.cover" alt="">
                            </div>
                            <div class="top-right">
                                <div class="item-title">{{liveInfo.title}}</div>
                                <div class="right-content">
                                    <div class="right-content-item">
                                        <span class="content-left">开播</span>
                                        <span class="content-right">{{liveInfo.live_broadcast_begin_time}}</span>
                                    </div>
                                    <div class="right-content-item">
                                        <span class="content-left">结束</span>
                                        <span class="content-right">{{liveInfo.live_broadcast_end_time}}</span>
                                    </div>
                                    <div class="right-content-item">
                                        <span class="content-left">主播</span>
                                        <span class="content-right">{{liveInfo.anchor_name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <span class="content-title">数据面板</span>
                    <div class="item-content">
                        <div class="second-item-content">
                            <div class="second-item" v-for="(item,index) in dataPanelList" :key="index"
                                 :style="{backgroundImage:'url('+item.background+')'}">
                                <div class="second-main-content">
                                    <span class="second-top-title">{{item.title}}</span>
                                    <span class="second-bottom-title">{{item.num}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <span class="content-title">商品控制</span>
                    <div class="item-content">
                        <div class="third-item-content">
                            <el-table class="customTable" :data="goodsList"
                                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                                <el-table-column prop="info" align="left" label="商品信息">
                                    <template slot-scope="scope">
                                        <div class="goods-infoBox">
                                            <div class="info-left">
                                                <img :src="scope.row.goods_img" alt="">
                                            </div>
                                            <div class="info-right">
                                                {{scope.row.goods_name}}
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="price" align="center" label="价格" width="200"></el-table-column>
                                <el-table-column prop="push_num" align="center" label="推送次数"
                                                 width="200"></el-table-column>
                                <el-table-column prop="price" align="center" label="操作" width="200">
                                    <template slot-scope="scope">
                                        <div class="link-box">
                                            <el-link type="success" :underline="false"
                                                     @click="pushGoods(scope.row)">
                                                推送
                                            </el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination class="pages-center" :total="goodsInfo.total"
                                           :current-page="goodsInfo.currentPageNum"
                                           :page-size="3"
                                           layout="prev, pager, next, jumper,slot"
                                           @current-change="goodsChange"></el-pagination>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <span class="content-title">抽奖池</span>
                    <div class="item-content">
                        <div class="fourth-item-content">
                            <el-table class="customTable" :data="lotteryList"
                                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                                <el-table-column prop="name" align="left" label="抽奖信息"></el-table-column>
                                <el-table-column prop="remarks" align="center" label="备注">
                                    <template slot-scope="scope">
                                        <span class="remark">{{scope.row.remarks?scope.row.remarks:'暂无'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="operation" align="center" label="状态"
                                                 width="200"></el-table-column>
                                <el-table-column prop="price" align="center" label="操作" width="200">
                                    <template slot-scope="scope">
                                        <div class="link-box">
                                            <el-link v-if="scope.row.operation == '未推送'" type="success"
                                                     :underline="false" @click="pushLottery(scope.row)">
                                                推送到主播
                                            </el-link>
                                            <el-link v-else type="danger" :underline="false"
                                                     @click="delLottery(scope.row)">
                                                删除
                                            </el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination class="pages-center" :total="lotteryInfo.total"
                                           :current-page="lotteryInfo.currentPageNum"
                                           :page-size="2"
                                           layout="prev, pager, next, jumper,slot"
                                           @current-change="lotteryChange"></el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import Pagination from "../../../../../components/pagination";
    import {
        stuOpLiveConsole,
        stuOpDrawList,
        stuOpDrawDel,
        stuOpGoodsPush,
        stuOpDrawPush,
        stuOpGoodsManagementList,
    } from "@/utils/apis";

    export default {
        name: "liveControl",
        components: {Pagination},
        data() {
            return {
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                dataPanelList: [
                    {
                        title: '当前在线人数',
                        num: '',
                        background: require('../../../../../assets/images/student/liveControl-bg1.png')
                    },
                    {
                        title: '观看人数',
                        num: '',
                        background: require('../../../../../assets/images/student/liveControl-bg2.png')
                    },
                    {
                        title: '观看次数',
                        num: '',
                        background: require('../../../../../assets/images/student/liveControl-bg3.png')
                    },
                    {
                        title: '平均观看时长',
                        num: '',
                        background: require('../../../../../assets/images/student/liveControl-bg4.png')
                    },
                ],
                liveInfo: {},
                goodsList: [],
                lotteryList: [],
                goodsInfo: {
                    total: 0,
                    currentPageNum: 1,
                },
                lotteryInfo: {
                    total: 0,
                    currentPageNum: 1,
                },
            }
        },
        created() {
            this.getControlList();
            this.getLotteryList();
            this.getGoodsManageList();
        },
        methods: {
            getControlList() {
                this.liveInfo = {};
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                }
                stuOpLiveConsole(param).then(res => {
                    if (res.data !== null) {
                        this.liveInfo = res.data;
                        this.dataPanelList[0].num = res.data.currently_online_num;
                        this.dataPanelList[1].num = res.data.watch_num;
                        this.dataPanelList[2].num = res.data.watch_frequency;
                        this.dataPanelList[3].num = res.data.average_watch_time;
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            getGoodsManageList() {
                this.goodsList = [];
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    page: this.goodsInfo.currentPageNum,
                    limit: 3
                }
                stuOpGoodsManagementList(param).then(res => {
                    if (res.data !== null) {
                        this.goodsList = res.data.list
                        this.goodsInfo.total = res.data.total
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            getLotteryList() {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    page: this.lotteryInfo.currentPageNum,
                    limit: 2
                }
                stuOpDrawList(param).then(res => {
                    if (res.data !== null) {
                        this.lotteryList = res.data.list
                        this.lotteryInfo.total = res.data.total
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            goBack() {
                this.$router.go(-1)
            },
            pushLottery(row) {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    id: row.id
                }
                stuOpDrawPush(param).then(res => {
                    this.getLotteryList();
                    this.$message.success(res.msg)
                }).catch(err => {
                    console.error(err)
                })
            },
            delLottery(row) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    stuOpDrawDel({id: row.id}).then((res) => {
                        this.getLotteryList();
                        this.$message.success(res.msg)
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            goodsChange(val) {
                this.goodsInfo.currentPageNum = val;
                this.getGoodsManageList();
            },
            lotteryChange(val) {
                this.lotteryInfo.currentPageNum = val;
                this.getLotteryList();
            },
            pushGoods(row) {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId,
                    id: row.id
                }
                stuOpGoodsPush(param).then(res => {
                    this.getGoodsManageList();
                    this.$message.success(res.msg)
                }).catch(err => {
                    console.error(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .live-content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 25px);
        padding: 0 0 25px;
        position: relative;

        .live-wrap {
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    padding: 0 20px;
                }
            }
        }

        .top-tab {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding-bottom: 10px;

            .genera-breadcrumb {
                display: flex;
                align-items: center;

                &:before {
                    content: unset;
                }
            }
        }

        .main-content {
            display: flex;
            flex-direction: column;
        }

        .content-item {
            margin-top: 20px;

            &:nth-of-type(2) {
                margin-top: 40px;
            }

            .item-content {
                margin-top: 20px;

                .top-item-content {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background: #F1F5FF;
                        bottom: -22px;

                    }

                    .top-left {
                        width: 356px;
                        height: 200px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .top-right {
                        height: 185px;
                        margin-left: 30px;

                        .item-title {
                            font-size: 24px;
                            color: #333333;
                        }

                        .right-content {
                            margin-top: 46px;
                            font-size: 18px;

                            .right-content-item + .right-content-item {
                                margin-top: 20px;
                            }

                            .content-right {
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .second-item-content {
                    display: flex;

                    .second-item {
                        width: 25%;
                        height: 120px;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }

                        .second-main-content {
                            padding: 23px 0 0 42px;
                            display: flex;
                            flex-direction: column;
                            color: #3F414E;

                            .second-top-title {
                                font-size: 16px;
                            }

                            .second-bottom-title {
                                margin-top: 20px;
                                font-size: 24px;
                            }
                        }
                    }
                }

                .third-item-content {
                    min-height: 240px;
                    display: flex;
                    flex-direction: column;

                    .goods-infoBox {
                        display: flex;

                        .info-left {
                            width: 80px;
                            height: 80px;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        .info-right {
                            font-size: 16px;
                            color: #333333;
                            margin-left: 15px;
                            display: flex;
                            align-items: center;
                        }

                    }
                }

                .fourth-item-content {
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;
                }

                .pages-center {
                    margin-top: 0;
                }
            }
        }

        .content-title {
            position: relative;
            font-size: 20px;
            color: #333333;
            margin-left: 16px;

            &:before {
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                background: #2DC079;
                left: -16px;
                border-radius: 3px;
                top: 0;
            }
        }
    }
</style>